import React from "react"
import "@src/scss/App.scss"
import Seo from "@components/seo"
import Layout from "@components/layout"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import ModuleRender from "@components/modules/moduleRender"
import BannerRender from "@components/modules/Banner/BannerRender"
import ArticleText from "@components/articleTextSidebar"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { useLocation } from "@reach/router"
const Pages = ({
  // pageContext,
  data,
  // location
}) => {
  const GET_REVIEW_TEAM = gql`
    query GetReviewDetail($country: String!) {
      reviews(
        publicationState: LIVE
        sort: "Date:DESC"
        where: { Country: $country }
      ) {
        id
        Date
        Person
        Text
        Country
      }
    }
  `

  var { pathname } = useLocation()
  var path = pathname.replaceAll("/", "")
  var pathVal = path.toUpperCase()

  var enbaleRightAlign = pathVal ? (pathVal === "UAE" ? true : false) : false
  var enbaleFrenchPage = pathVal ? (pathVal === "FR" ? true : false) : false

  var currentCountryName =
    pathVal.length > 0
      ? (pathVal === "RU" && "Russian") ||
        (pathVal === "CN" && "China") ||
        (pathVal === "FR" && "France") ||
        (pathVal === "UAE" && "United Arab Emirates") ||
        (pathVal === "PL" && "Polish")
      : ""

  const {
    loading: loadingR,
    error: errorR,
    data: dataR,
  } = useQuery(GET_REVIEW_TEAM, {
    variables: { country: pathVal },
  })
  const {
    glstrapi: { page, popularSearch },
  } = data
  const pageurl = typeof window !== 'undefined' ? window.location.pathname : page?.Slug
  const breadcrumbs = [
    {
      ...(page?.Parent
        ? { label: page?.Parent?.Pagename, url: `/${page?.Parent?.Slug}` }
        : ""),
    },
    {
      ...(page?.Parent2
        ? {
            label: page?.Parent2?.Pagename,
            url: `/${page?.Parent?.Slug}/${page?.Parent2?.Slug}`,
          }
        : ""),
    },
    { label: page?.Pagename, url: `${pageurl}` },
    
  ]
  let bodyClass, headerClass
  if (page?.HeaderTemplate === "Terms") {
    bodyClass = "noBannerImage list-property-page"
    headerClass = "header-dark"
  }
  if (typeof window !== "undefined") {
    window.sessionStorage.setItem("Page", window.location.pathname)
    // window.addEventListener('contextmenu', function (e) {
    //     e.preventDefault();
    //   });
  }

  const multiCountryPageCheck = popularSearch.Country_Menu.map(
    item => item.label
  ).includes(path)
  const menu_list = page.BannerImage.find(
    item => item.__typename === "GLSTRAPI_ComponentModulesMenuList"
  )
  const contact_label_list = page.BannerImage.find(
    item => item.__typename === "GLSTRAPI_ComponentModulesContactFormFields"
  )
  const footer_content = page.BannerImage.find(
    item => item.__typename === "GLSTRAPI_ComponentModulesFooterContent"
  )
  const CountryFlagStatus = menu_list?.Why_Exclusive_Links ? true : false
  return (
    <Layout
      enbaleRightAlign={enbaleRightAlign}
      enbaleFrenchPage={enbaleFrenchPage}
      popularSearch={page?.Popular_Search}
      bodyClass={bodyClass}
      headerClass={headerClass}
      menu_list={menu_list}
      footer_content={footer_content}
    >
      {page.Pagename === "Property Management in Dubai" && (
        <Helmet>
          <script type="application/ld+json">{`
                        {
                            "@context": "http://schema.org",
                            "@type": "Product",
                            "name": "Property Management Companies in Dubai",
                            "aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue": "4.6",
                                "bestRating": "5",
                                "ratingCount": "252"
                            }
                        }
                    `}</script>
          <script type="application/ld+json">{`
                        {
                            "@context": "http://schema.org",
                            "@type": "VideoObject",
                            "name": "Property Management Companies in Dubai",
                            "description": "At Exclusive Links, our property management company in Dubai is dedicated to offering comfort and efficient solutions for property owners. We understand that selecting a top-tier property management company in Dubai is a crucial choice. As one of the premier real estate brokers in Dubai, Exclusive Links is fully licensed and backed by a government bond, providing our clients with added protection and peace of mind. We are proud to deliver exceptional services to protect and enhance your property investment.",
                            "thumbnailUrl": "https://i.ytimg.com/vi/KbL-4ilNMQo/default.jpg",
                            "uploadDate": "2022-06-01T13:19:48Z",
                            "duration": "PT2M39S",
                            "embedUrl": "https://www.youtube.com/embed/KbL-4ilNMQo",
                            "interactionCount": "17"
                        }
                    `}</script>
        </Helmet>
      )}
      <Seo
        title={page?.Metadata?.title ? page.Metadata.title : page.Title}
        description={
          page?.Metadata?.description ? page.Metadata.description : null
        }
        // image={page?.Metadata?.image?.url ? page.Metadata.image.url : null}
        image={
          page?.Metadata?.image?.url_sharp?.childImageSharp
            ? page.Metadata.image.url_sharp.childImageSharp.resize
            : null
        }
      />
      <BannerRender
        page={page}
        CountryFlagStatus={multiCountryPageCheck}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      {page?.ArticleContent && (
        <ArticleText
          moduleData={page?.ArticleContent}
          sidebarData={page?.SidebarContent}
        />
      )}
      {page?.BannerImage && (
        <ModuleRender
          CountryFlagStatus={CountryFlagStatus}
          moduleData={page?.BannerImage}
          reviews={dataR?.reviews}
          currentCountryName={currentCountryName}
          path={path}
          contact_label_list={contact_label_list}
        />
      )}
    </Layout>
  )
}

export default Pages

export const query = graphql`
  query defaultQuery($id: ID!) {
    glstrapi {
      popularSearch {
        __typename
        Country_Menu {
          Show
          label
          value
        }
      }
      page(id: $id, publicationState: LIVE) {
        id
        Pagename
        Title
        Slug
        PageTemplate
        HeaderTemplate
        Popular_Search
        Parent {
          Pagename
          Slug
        }
        Parent2 {
          Pagename
          Slug
        }
        Parent3 {
          Pagename
          Slug
        }
        imagetransforms
        BannerImage {
          ... on GLSTRAPI_ComponentModulesBannerCarousel {
            ...ImageBannerCarouselFragment
          }
          ... on GLSTRAPI_ComponentModulesTeamModule {
            ...TeamModuleFragment
          }
          ... on GLSTRAPI_ComponentModulesAboutPoints {
            ...AboutPointsModuleFragment
          }
          ... on GLSTRAPI_ComponentModulesMenuList {
            __typename
            id
            Contact
            List_Your_Property
            Services
            Why_Dubai
            Why_Exclusive_Links
          }
          ... on GLSTRAPI_ComponentModulesCustomerReviewModule {
            id
            moduleType
            ReviewSectionTitle
            ReviewSectionDesc
          }
          ... on GLSTRAPI_ComponentModulesContactFormFields {
            id
            __typename
            Email_Address
            First_Name
            Last_Name
            Required
            write_your_message_here
            Message
            Submit_Enquiry
            Telephone_Number
            Terms_Conditions
            Were_here_to_help
            contact_us
            Email_us
            view_our_office
          }
          ... on GLSTRAPI_ComponentModulesFooterContent {
            id
            __typename
            Sitemap
            contact_us
            content
            copyright
            email_us
            help_you_heading
            News_Letter
            privacy_policy
            rights
            site_by
            trending_searches
            terms_conditions
            whatsapp_us
            services_menu {
              buy
              off_plan
              property_management
              luxury_property
              rent
              sell
              services
              vacation_homes
            }
            others {
              about
              contact
              media
              meet_the_team
              others
              recuirement
              reviews
            }
          }
          ... on GLSTRAPI_ComponentModulesAllInternationalProjects {
            ...InternationalProjectsFragment
          }
          ... on GLSTRAPI_ComponentModulesBannerImage {
            ...ImageBannerFragment
            ...ImgBtnFragment
            ...ImgBtnNormalFragment
            ...TermsBannerFragment
            BannerCTAButtons {
              id
              label
              manualLink
              func
            }
          }
          ... on GLSTRAPI_ComponentModulesReviews {
            ...ReviewsFragment
          }
          ... on GLSTRAPI_ComponentModulesSearchCta {
            ...PropertySearchFragment
          }
          ... on GLSTRAPI_ComponentModulesIconBox {
            ...IconBoxFragment
          }
          ... on GLSTRAPI_ComponentModulesPropertySlider {
            ...PropertySliderFragment
          }
          ... on GLSTRAPI_ComponentModulesBoxCta {
            ...BoxCtaFragment
          }
          ... on GLSTRAPI_ComponentModulesAboutPeople {
            ...AboutPeopleFragment
          }
          ... on GLSTRAPI_ComponentModulesAwards {
            ...AwardsSliderFragment
          }
          ... on GLSTRAPI_ComponentModulesGoogleCta {
            ...GoogleCtaFragment
          }
          ... on GLSTRAPI_ComponentModulesLatestPodcasts {
            ...LatestPodcastsFragment
          }
          ... on GLSTRAPI_ComponentModulesLatestVideos {
            ...LatestVideosFragment
          }
          ... on GLSTRAPI_ComponentModulesStats {
            ...StatsFragment
          }
          ... on GLSTRAPI_ComponentModulesImageBoxCarousel {
            ...ImageBoxCarouselFragment
          }
          ... on GLSTRAPI_ComponentModulesFaq {
            ...FaqFragment
          }
          ... on GLSTRAPI_ComponentModulesJobs {
            ...JobsFragment
          }
          ... on GLSTRAPI_ComponentModulesAllAreas {
            ...AllAreasFragment
          }
          ... on GLSTRAPI_ComponentModulesGoogleMaps {
            ...GoogleMapsFragment
          }
          ... on GLSTRAPI_ComponentModulesAllProjects {
            ...AllProjectsFragment
          }
          ... on GLSTRAPI_ComponentModulesListProjectStyle {
            ...ListProjectStyleFragment
          }
          ... on GLSTRAPI_ComponentModulesMultiLandContact {
            id
            moduleData {
              Description
              Title
            }
            moduleType
          }
        }
        SidebarContent {
          ... on GLSTRAPI_ComponentModulesTeamContact {
            ...TeamContactFragment
          }
          ... on GLSTRAPI_ComponentModulesSocialShare {
            ...SocialShareFragment
          }
        }
        ArticleContent {
          ... on GLSTRAPI_ComponentModulesNormalText {
            ...NormalTextFragment
          }
          ... on GLSTRAPI_ComponentModulesIntroText {
            ...IntroTextFragment
          }
          ... on GLSTRAPI_ComponentModulesFaq {
            ...FaqFragment
          }
          ... on GLSTRAPI_ComponentModulesImageGrid {
            ...ImageGridFragment
          }
          ... on GLSTRAPI_ComponentModulesBuyersGuideForm {
            ...BuyersGuideFormFragment
          }
          ... on GLSTRAPI_ComponentModulesBookValuation {
            ...BookValuationFragment
          }
          ... on GLSTRAPI_ComponentModulesManagementGuideForm {
            ...ManagementGuideFormFragment
          }
          ... on GLSTRAPI_ComponentModulesTableList {
            ...TableListFragment
          }
          ... on GLSTRAPI_ComponentModulesAccordion {
            ...AccordionFragment
          }
        }
        Metadata {
          title
          description
          image {
            url
            url_sharp {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
